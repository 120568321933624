// Needs to be here to make the entry work
// eslint-disable-next-line no-unused-vars
import React from 'react';

//Entry configuration
import theme from 'hsi/themes/cision';
import config from 'hsi/configurations/luqi/config';

//Utils
import entryFactory from 'hsi/utils/entry-factory';

//Init the app
entryFactory('luqi', config, theme, {});
